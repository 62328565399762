.contact-container {
  height: 100vh;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 2000 2000' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)' /%3E%3C/svg%3E");
  background-color: #e91212;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact-content h1 {
  color: white;
  font-size: 4em;
  padding-bottom: 20px;
  opacity: 0.7;
}

.contact-title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  text-align: start;
  width: 70%;
}
.contact-links {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  font-size: 2em;
  cursor: pointer;
}

.contact-link {
  position: relative;
}

.contact-link::before {
  content: '';
  position: absolute;
  bottom: -3px;
  height: 8px;
  width: 0;
  left: 2px;
  background: red;
  opacity: 0.8;
  transition: width 0.3s ease-in-out;
}

:hover::before {
  width: 100%;
}

@media (max-width: 768px) {
  .contact-content h1 {
    color: white;
    font-size: 1.5em;
    padding-bottom: 20px;
    opacity: 0.7;
  }

  .contact-title {
    width: 80%;
  }
  .contact-links {
    justify-content: space-around;
    padding: 0 5px;
    font-size: 1.5em;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .contact-content h1 {
    color: white;
    font-size: 1em;
    padding-bottom: 20px;
    opacity: 0.7;
  }

  .contact-title {
    width: 80%;
  }

  .contact-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    justify-content: space-around;
    font-size: 1.2em;
  }
}
