.portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.portfolio-img {
  width: 550px;
  height: 800px;
}

.portfolio-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
