.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  font-size: 10em;
}

.row-portfolio {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 15em;
  font-family: 'Roboto';
}

.text-container {
  position: relative;
  overflow: hidden;
  display: block;
}

.highlighted-text:before {
  content: attr(data-text);
  display: inline-block;
  opacity: 1;
}

.highlighted-text {
  color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 3333;
  transition: clip-path 0.1s ease;
  text-shadow: 0 0 0 #111;
}

.static-text {
  color: white; /* Texte transparent */
  // -webkit-text-stroke: 1px black; /* Contour noir */
}

@media (max-width: 768px) {
  .image-container {
    position: relative;
  }
  .row-portfolio {
    font-size: 10em;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .row-portfolio {
    font-size: 6em;
  }
}
